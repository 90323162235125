// import React, { useEffect, useMemo, useState } from "react";
// import { useTranslation } from "react-i18next";
// import * as moment from "moment";
// import { Link } from "react-router-dom";
// import {
//   Card,
//   CardBody,
//   CardHeader,
//   Col,
//   Container,
//   Input,
//   Nav,
//   NavItem,
//   NavLink,
//   Row,
// } from "reactstrap";
// import BreadCrumb from "../../../Components/Common/BreadCrumb";
// import TableContainer from "../../../Components/Common/TableContainer";
// import Loader from "../../../Components/Common/Loader";
// import useHookForm from "../../../hooks/useHookForm";
// import classnames from "classnames";
// import { useQuery } from "@tanstack/react-query";
// import { getOrders2Query } from "../../../queries";
// import createSchema from "../../../helpers/createSchema";
// import { getOrders2 } from "../../../api";
// import ExportCSVModal from "../../../Components/Common/ExportCSVModal";
// import { prettify } from "../ui/prettify";
// import ReserveTable from "../ui/reserve-table";
// const values = {
//   page: 1,
//   size: 20,
//   desc: true,
//   subOrderState: ""
// };
// const schema = createSchema({});
// const Orders2 = () => {
//   const tableKey = "orders2";
//   const { t } = useTranslation();
//   const [allDataLoading, setAllDataLoading] = useState(false);
//   const [dataForDownload, setDataForDownload] = useState([]);
//   const [exportModal, setExportModal] = useState(false);
//   const { watch, setValue } = useHookForm(values, schema);

//   const { data, isFetched } = useQuery({
//     ...getOrders2Query({ ...watch() }),
//   });

//   useEffect(() => {
//     const storedPageSize = JSON.parse(localStorage.getItem("pageSizes")) || {};
//     const pageSize = storedPageSize[tableKey] || watch("size");
//     setValue("size", pageSize);
//   }, [setValue]);
//   const handlePageSizeChange = (e) => {
//     const newSize = parseInt(e.target.value, 10);
//     setValue("size", newSize);
//     setValue("page", 1);
//     const storedPageSizes = JSON.parse(localStorage.getItem("pageSizes")) || {};
//     storedPageSizes[tableKey] = newSize;
//     localStorage.setItem("pageSizes", JSON.stringify(storedPageSizes));
//   };

//   const getAllData = () => {
//     setAllDataLoading(true);
//     getOrders2({
//       ...watch(),
//       size: data?.pagination?.TotalCount,
//     })
//       .then((res) => {
//         setDataForDownload(res?.result?.items?.[0]);

//         setExportModal(true);
//       })
//       .finally(() => setAllDataLoading(false));
//   };

//   const columns = useMemo(
//     () => [
//       {
//         Header: t("date"),
//         accessor: (row) => moment(row.createdDate).format("YYYY.MM.DD"),
//         filterable: false,
//       },
//       {
//         Header: t("Price"),
//         accessor: (row) =>
//           prettify(
//             row.subOrders?.[0]?.items?.[0].variation?.prices?.find(
//               (item) => item.type === "Price"
//             ).value
//           ),
//         filterable: false,
//       },
//     ],
//     []
//   );

//   return (
//     <div className="page-content">
//       {/* <ExportCSVModal
//         show={exportModal}
//         onCloseClick={() => setExportModal(false)}
//         data={dataForDownload}
//         fileName="ЗАЯВКИ"
//       /> */}
//       <Container fluid>
//         <BreadCrumb title={t("reserve")} pageTitle={t("home")} />
//         <Row>
//           <Col lg={12}>
//             <Card id="orderList">
//               <CardHeader className="card-header border-0">
//                 <Row className="align-items-center gy-3">
//                   <div className="col-sm">
//                     <h5 className="card-title mb-0">{t("reserve")}</h5>
//                   </div>
//                   {/* <div className="col-sm-auto">
//                     <div className="d-flex gap-1 flex-wrap">
//                       <button
//                         type="button"
//                         className="btn btn-info"
//                         onClick={getAllData}
//                         disabled={allDataLoading}
//                       >
//                         <i className="ri-file-download-line align-bottom me-1"></i>{" "}
//                         {t(allDataLoading ? "downloading" : "export")}
//                       </button>
//                     </div>
//                   </div> */}
//                 </Row>
//               </CardHeader>
//               <CardBody className="pt-0">
//                 <div>
//                   <Nav
//                     className="nav-tabs nav-tabs-custom nav-success"
//                     role="tablist"
//                   >
//                     <NavItem>
//                       <NavLink
//                         className={classnames(
//                           { active: watch("subOrderState") === "" },
//                           "fw-semibold"
//                         )}
//                         onClick={() => setValue("subOrderState", "")}
//                         href="#"
//                       >
          
//                         {t("all_reserve")}
//                       </NavLink>
//                     </NavItem>
//                     <NavItem>
//                       <NavLink
//                         className={classnames(
//                           { active: watch("subOrderState") === 0 },
//                           "fw-semibold"
//                         )}
//                         onClick={() => setValue("subOrderState", 0)}
//                         href="#"
//                       >
                
//                         {t("reserve_created")}
//                       </NavLink>
//                     </NavItem>
//                     <NavItem>
//                       <NavLink
//                         className={classnames(
//                           { active: watch("subOrderState") === 1 },
//                           "fw-semibold"
//                         )}
//                         onClick={() => setValue("subOrderState", 1)}
//                         href="#"
//                       >
                    
//                         {t("reserve_undrway")}
//                       </NavLink>
//                     </NavItem>
//                     <NavItem>
//                       <NavLink
//                         className={classnames(
//                           { active: watch("subOrderState") === 2 },
//                           "fw-semibold"
//                         )}
//                         onClick={() => setValue("subOrderState", 2)}
//                         href="#"
//                       >
                     
//                         {t("reserve_sent")}
//                       </NavLink>
//                     </NavItem>
//                     <NavItem>
//                       <NavLink
//                         className={classnames(
//                           { active: watch("subOrderState") === 3 },
//                           "fw-semibold"
//                         )}
//                         onClick={() => setValue("subOrderState", 3)}
//                         href="#"
//                       >
                 
//                         {t("reserve_complated")}
//                       </NavLink>
//                     </NavItem>
//                     <NavItem>
//                       <NavLink
//                         className={classnames(
//                           { active: watch("subOrderState") === 4 },
//                           "fw-semibold"
//                         )}
//                         onClick={() => setValue("subOrderState", 4)}
//                         href="#"
//                       >
                   
//                         {t("reserve_cancelled")}
//                       </NavLink>
//                     </NavItem>
//                     <NavItem>
//                       <NavLink
//                         className={classnames(
//                           { active: watch("subOrderState") === 5 },
//                           "fw-semibold"
//                         )}
//                         onClick={() => setValue("subOrderState", 5)}
//                         href="#"
//                       >
            
//                         {t("reserve_refund")}
//                       </NavLink>
//                     </NavItem>
//                   </Nav>

//                   {isFetched ? (
//                     <>
//                       <ReserveTable
//                         data={data?.result?.items}
//                         pagination={{
//                           currentPage: watch("page"),
//                           totalPages: data?.pagination?.TotalPages,
//                           onChange: (page) => setValue("page", page),
//                         }}
//                       />

//                       <Input
//                         type="select"
//                         name="pageSize"
//                         id="pageSize"
//                         value={watch("size")}
//                         onChange={handlePageSizeChange}
//                         className="w-auto"
//                       >
//                         <option value={20}>{t("20")}</option>

//                         <option value={50}>{t("50")}</option>
//                         <option value={100}>{t("100")}</option>
//                         <option value={200}>{t("200")}</option>
//                       </Input>
//                     </>
//                   ) : (
//                     <Loader />
//                   )}
//                 </div>
//               </CardBody>
//             </Card>
//           </Col>
//         </Row>
//       </Container>
//     </div>
//   );
// };

// export default Orders2;

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
} from "reactstrap";
import Loader from "../../../Components/Common/Loader";
import { useQuery } from "@tanstack/react-query";
import classnames from "classnames";
import ReserveTable from "../ui/reserve-table";
import { toast, ToastContainer } from "react-toastify";
import CustomModal from "../../../Components/Common/Modal";
import { getOrders2Query } from "../../../queries";
const values = {
  page: 1,
  size: 20,
  phoneNumber: null,
  name: null,
  subOrderState: ""
};

const Orders2 = () => {
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState(values);
  const [open, setOpen] = useState(false);
  const [filterValues, setFilterValues] = useState({
    phoneNumber: "",
    name: "",
  });
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const { data, isFetched } = useQuery({
    ...getOrders2Query(formValues),
    onError: (err) => {
      if (err.response && err.response.status === 500) {
        toast.error(
          t("An error occurred on the server. Please try again later.")
        );
      }
    },
  });
  
  const toggleModal = () => {
    setOpen((prev) => !prev);
  };
  const handleNavClick = (value) => {
    setFormValues((prevState) => ({ ...prevState, subOrderState: value }));
  };

  const handlePageSizeChange = (e) => {
    const newSize = parseInt(e.target.value);
    setFormValues((prevState) => ({
      ...prevState,
      size: newSize,
      page: 1,
    }));
  };

  const handlePageChange = (page) => {
    setFormValues((prevState) => ({ ...prevState, page }));
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilterValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmitFilter = (e) => {
    e.preventDefault();

    setFormValues((prevState) => ({
      ...prevState,
      phoneNumber: filterValues.phoneNumber,
      name: filterValues.name,
      page: 1,
    }));
    setIsFilterApplied(true)
    toggleModal();
  };

  const handleReset = () => {
    setFilterValues({
      phoneNumber: "",
      name: "",
    });
    setIsFilterApplied(false)

    
  };

  useEffect(() => {
    const hasFilters = filterValues.name || filterValues.phoneNumber;
    setIsFilterApplied(!!hasFilters); 
  }, [formValues]);

  return (
    <div className="page-content">
      <ToastContainer position="top-center" />
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card id="orderList">
              <CardHeader className="card-header border-0">
                <Row className="align-items-center gy-3">
                  <div className="col-sm">
                    <h5 className="card-title mb-0">{t("reserve")}</h5>
                  </div>
                </Row>
              </CardHeader>
              <CardBody className="pt-0">
                <div>
                  <div className="d-flex justify-content-between align-items-center">
                  <Nav
                    className="nav-tabs nav-tabs-custom nav-success"
                    role="tablist"
                  >
           
                     {["", 0, 1, 2, 3, 4, 5].map((state, index) => (
                        <NavItem key={state}>
                          <NavLink
                            className={classnames(
                              { active: formValues?.subOrderState === state },
                              "fw-semibold"
                            )}
                            onClick={() => handleNavClick(state)}
                            href="#"
                          >
                            {t(
                              [
                                "all_reserve",
                                "reserve_created",
                                "reserve_undrway",
                                "reserve_sent",
                                "reserve_complated",
                                "reserve_cancelled",
                                "reserve_refund",
                              ][index]
                            )}
                          </NavLink>
                        </NavItem>
                      ))}
                  </Nav>
                   <Button
                  onClick={toggleModal}
                  color={isFilterApplied ? "primary": "light"}
                  className="d-flex align-items-center gap-1"
                >
                  <i className="ri-equalizer-fill"></i>
                  {t("Filter")}
                </Button>
                  </div>
                  {isFetched ? (
                    <div className="mt-2">
                      <ReserveTable
                        data={data?.result?.items}
                        pagination={{
                          currentPage: formValues?.page,
                          totalPages: data?.pagination?.TotalPages,
                          onChange: handlePageChange,
                        }}
                      />

                      <Input
                        type="select"
                        name="pageSize"
                        id="pageSize"
                        value={formValues?.size}
                        onChange={handlePageSizeChange}
                        className="w-auto"
                      >
                         {[20, 50, 100].map((size) => (
                          <option key={size} value={size}>
                            {t(String(size))}
                          </option>
                        ))}
                      </Input>
                    </div>
                  ) : (
                    <Loader />
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
          <CustomModal
            isOpen={open}
            toggleModal={toggleModal}
            title={t("Filter")}
            content={
              <Form onSubmit={handleSubmitFilter} className="d-flex flex-column gap-2">
              <div>
              <Label>{t("phone")}</Label>
                 <Input
                   type="tel"
                   name="phoneNumber"
                   value={filterValues.phoneNumber}
                   onChange={handleFilterChange}
                   placeholder={t("phone")}
                 />
              </div>
                <div>
                <Label>{t("client_full_name")}</Label>
                 <Input
                   type="text"
                   name="name"
                   value={filterValues.name}
                   onChange={handleFilterChange}
                   placeholder={t("client_full_name")}
                 />
                </div>
                 <div className="d-flex justify-content-end gap-3">
                 <Button onClick={handleReset} color="light" type="submit">{t("reset")}</Button>
                 <Button disabled={!filterValues.name && !filterValues.phoneNumber} type="submit">{t("Save")}</Button>
                 </div>
               </Form>
            }
          />
           
         
        </Row>
      </Container>
    </div>
  );
};

export default Orders2;
