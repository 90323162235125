import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Label,
  Nav,
  NavItem,
  NavLink,
  Progress,
  Spinner,
  TabContent,
  TabPane,
  Input as ReactInput,
} from "reactstrap";
import { useFieldArray } from "react-hook-form";
import useHookForm from "../../../hooks/useHookForm";
import { ProductsPost, getAttributesId, uploadFiles } from "../../../api";
import { Input } from "../../../Components/atoms/input";
import createSchema from "../../../helpers/createSchema";
import { Controller } from "react-hook-form";
import Select from "react-select";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getCategoriesQuery, getProfileQuery } from "../../../queries";
import classnames from "classnames";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import Dropzone from "react-dropzone";
import AddProductForm from "./Components/add-product-form";
import * as yup from "yup";
import ProductForm from "./Components/product-form";
import { Textarea } from "../../../Components/atoms/textarea";
import { InputLabel } from "../../../Components/atoms/inputLabel";
//commit
const values = {
  name: {
    ru: "",
    "uz-Cyrl-UZ": "",
    "uz-Latn-UZ": "",
  },
  description: {
    ru: "",
    "uz-Cyrl-UZ": "",
    "uz-Latn-UZ": "",
  },
  categoryId: null,
  attrs: [],
  prices: [
    { type: "Price", value: "" },
    { type: "Vat", value: "" },
    { type: "Sale", value: "" },
  ],
  files: [],
  saleType: [],
};

const AddProducts = () => {
  const createSchema = () => {
    return yup.object().shape({
     
      attrs: yup.array().of(
        yup.object().shape({
          value: yup.mixed().when(["isRequired", "isVisible"], {
            is: (isRequired, isVisible) => isRequired && isVisible,
            then: yup
              .mixed()
              .when("filter.filterType", {
                is: (val) => val === "Range" || val === "Price",
                then: yup.string().required(t("required_field_error")),
              })
              .when("filter.filterType", {
                is: (val) => val === "SingleSelect",
                then: yup.string().required(t("required_field_error")),
              })
              .when("filter.filterType", {
                is: (val) => val === "MultipleSelect",
                then: yup.string().required(t("At least one must be selected")),
                otherwise: yup.string().nullable(),
              }),
            otherwise: yup.string().nullable(),
          }),
          valueTranslations: yup.mixed().when(["isRequired", "isVisible"], {
            is: (isRequired, isVisible) => isRequired && isVisible,
            then: yup.mixed().when("isValueTranslated", {
              is: true,
              then: yup.object().shape({
                ru: yup.string().required(t("required_field_error")),
                "uz-Cyrl-UZ": yup.string().required(t("required_field_error")),
                "uz-Latn-UZ": yup.string().required(t("required_field_error")),
              }),
              otherwise: yup.object().nullable(),
            }),
            otherwise: yup.object().nullable(),
          }),
        })
      ),
      files: yup
        .array()
        .min(1, t("At least one must be selected"))
        .required(t("At least one must be selected")),
      saleType: yup
        .array()
        .min(1, t("At least one must be selected"))
        .required(t("At least one must be selected")),
      name: yup.object().shape({
        ru: yup.string().required(t("required_field_error")),
        "uz-Latn-UZ": yup.string().required(t("required_field_error")),
        "uz-Cyrl-UZ": yup.string().required(t("required_field_error")),
      }),
      description: yup.object().shape({
        ru: yup.string().required(t("required_field_error")),
        "uz-Latn-UZ": yup.string().required(t("required_field_error")),
        "uz-Cyrl-UZ": yup.string().required(t("required_field_error")),
      }),
    });
  };

  const schema = createSchema();

  const {
    control,
    watch,
    handleSubmit,
    setValue,
    reset,
    setError,
    formState: { errors },
  } = useHookForm(values, schema);
  const [activeTab, setactiveTab] = useState(1);
  const [progressbarvalue, setprogressbarvalue] = useState(0);
  const [passedSteps, setPassedSteps] = useState([1]);
  const [loading, setLoading] = useState(false);
  const [imageFiles, setImageFiles] = useState([]);
  const [fileInputs, setFileInputs] = useState([0]);
  const [filePreviews, setFilePreviews] = useState([]);
  const [draggedIndex, setDraggedIndex] = useState(null);
  const [deletedFiles, setDeletedFiles] = useState([]);
  const [show, setShow] = useState(false);
  const [productLoading, setProductLoading] = useState(false);
  const [attribute, setAttribute ] = useState([])
  const customAttribute = attribute
  const errorMessageRef = useRef(null);
  const client = useQueryClient();
  const navigate = useNavigate();
  const maxFileSize = 10 * 1024 * 1024;

  const handleFileUpload = (acceptedFiles) => {
    let newImageFiles = [...imageFiles];
    let newPreviews = [...filePreviews];
    let newFileInputs = [...fileInputs];

    acceptedFiles.forEach((file, index) => {
      const currentIndex = imageFiles.length + index;
      newImageFiles[currentIndex] = file;
      newPreviews[currentIndex] = URL.createObjectURL(file);
      newFileInputs.push(newFileInputs.length);
    });

    setImageFiles(newImageFiles);
    setFilePreviews(newPreviews);
    setFileInputs(newFileInputs);

    setValue("files", newImageFiles, { shouldValidate: true });
  };

  const handleDragStart = (index) => {
    setDraggedIndex(index);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (index) => {
    if (draggedIndex === null) return;

    const updatedImageFiles = [...imageFiles];
    const updatedPreviews = [...filePreviews];

    // Swap the dragged image with the dropped position
    [updatedImageFiles[draggedIndex], updatedImageFiles[index]] = [
      updatedImageFiles[index],
      updatedImageFiles[draggedIndex],
    ];

    [updatedPreviews[draggedIndex], updatedPreviews[index]] = [
      updatedPreviews[index],
      updatedPreviews[draggedIndex],
    ];

    setImageFiles(updatedImageFiles);
    setFilePreviews(updatedPreviews);
    setDraggedIndex(null); // Reset dragged index after swap
  };

  const handleDeleteImage = (index) => {
    const currentFiles = [...watch("files")];

    const deletedFile = currentFiles[index];
    setDeletedFiles((prev) => [...prev, deletedFile]);

    currentFiles.splice(index, 1);
    setValue("files", currentFiles);

    const updatedPreviews = [...filePreviews];
    updatedPreviews.splice(index, 1);
    setFilePreviews(updatedPreviews);

    const updatedInputs = [...fileInputs];
    updatedInputs.splice(index, 1);
    setFileInputs(updatedInputs);
  };

  const onDropAccepted = (acceptedFiles) => {
    if (errorMessageRef.current) {
      errorMessageRef.current.textContent = "";
    }
    handleFileUpload(acceptedFiles);
  };

  const onDropRejected = (rejectedFiles) => {
    const hasFileTooLarge = rejectedFiles.some((file) =>
      file.errors.some((error) => error.code === "file-too-large")
    );

    if (hasFileTooLarge) {
      if (errorMessageRef.current) {
        const fileSizeInMB = (
          rejectedFiles[0].file.size /
          (1024 * 1024)
        ).toFixed(2);
        errorMessageRef.current.textContent =
          t("File size must not exceed 10 MB!") +
          " " +
          `File size ${fileSizeInMB} MB`;
      }
    }
  };

  function toggleTab(tab, value) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab];

      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab);
        setPassedSteps(modifiedSteps);
      }
    }
    setprogressbarvalue(value);
  }

  const { data: categories } = useQuery({
    ...getCategoriesQuery(),
  });

  const { data: profile } = useQuery({
    ...getProfileQuery(),
  });

  const handleSaleTypeChange = (type) => {
    const saleTypeValues = watch("saleType");
    const updatedSaleType = saleTypeValues.includes(type)
      ? saleTypeValues.filter((t) => t !== type)
      : [...saleTypeValues, type];
    setValue("saleType", updatedSaleType);
  };

  const handleMultipleSelectChange = (field, value, e) => {
    let newValue = field.value.split(",").filter(Boolean);
    if (e.target.checked) {
      newValue.push(value.trim());
    } else {
      newValue = newValue.filter((v) => v !== value.trim());
    }
    field.onChange(newValue.join(","));
  };

  const calculateSaleType = () => {
    return watch("saleType").reduce((acc, type) => acc + type, 0);
  };

  const Submit = async (data) => {
    setLoading(true);
    const attributeId = watch("categoryId")?.value;
    try {
      const res = await getAttributesId(attributeId);
      const arr = res.item.map((item) => ({
        ...item,
        attributeId: item.id,
        value: "",
        valueTranslations: {
          ru: "",
          "uz-Latn-UZ": "",
          "uz-Cyrl-UZ": "",
        },
      }));
      setValue("attrs", arr);
      client.invalidateQueries({ queryKey: ["products"] });
      setLoading(true) ? setShow(false) : setShow(true);
    } catch (error) {
      alert(error?.data?.errors?.errorMessage);
    } finally {
      setLoading(false);
    }
  };
  const ProductSubmit = async (data) => {
    
    if (!data?.prices?.[0].value) {
      return setError("prices[0].value", {
        type: "manual",
        message: t("required_field_error"),
      });
    }

    if (!data?.prices?.[1].value) {
      return setError("prices[1].value", {
        type: "manual",
        message: t("required_field_error"),
      });
    }
    if (
      data.prices[2].value !== "" &&
      +data.prices[0].value > +data.prices[2].value
    ) {
      return setError("prices[2].value", {
        type: "manual",
        message: t("price_2_error"),
      });
    }

    setProductLoading(true);
    let index = 1;
    const uploadedFiles = [];

    const filteredImageFiles = imageFiles.filter(
      (file) => !deletedFiles.includes(file)
    );

    for (const file of filteredImageFiles) {
      const formData = new FormData();
      formData.append("files", file);

      const resImage = await uploadFiles(formData);
      const imageObj = resImage?.[0];

      uploadedFiles.push({
        order: index,
        url: imageObj?.url,
        fileId: imageObj?.id,
        variationId: null,
        productId: null,
      });

      index++;
    }

    const names = Object.keys(data.name).map((languageCode) => ({
      languageCode,
      text: watch(`name.${languageCode}`),
    }));

    const descriptions = Object.keys(data.description).map((languageCode) => ({
      languageCode,
      text: watch(`description.${languageCode}`),
    }));

    const customAttributes = attribute.map((attr) => ({
      attributeId: attr.attributeId || null,
      value: attr.value || "",
      productId: null,
      valueTranslations: [
        { languageCode: "ru", text: attr.valueTranslations?.ru || "" },
        { languageCode: "uz-Latn-UZ", text: attr.valueTranslations?.["uz-Latn-UZ"] || "" },
        { languageCode: "uz-Cyrl-UZ", text: attr.valueTranslations?.["uz-Cyrl-UZ"] || "" },
      ],
      variationId: null,
      attribute: {
        weight: 0,
        dataType: "Text",
        hasFilter: false,
        isValueTranslated: true,
        isAdditional: true,
        isRequired: true,
        name: [
          { languageCode: "ru", text: attr.attribute?.name?.ru || "" },
          { languageCode: "uz-Latn-UZ", text: attr.attribute?.name?.["uz-Latn-UZ"] || "" },
          { languageCode: "uz-Cyrl-UZ", text: attr.attribute?.name?.["uz-Cyrl-UZ"] || "" },
        ] || null,
        description: [
          { languageCode: "ru", text: attr.attribute?.description?.ru || "" },
          { languageCode: "uz-Latn-UZ", text: attr.attribute?.description?.["uz-Latn-UZ"] || "" },
          { languageCode: "uz-Cyrl-UZ", text: attr.attribute?.description?.["uz-Cyrl-UZ"] || "" },
        ] || null,
        id: null,
        isVisible: true,
        categoryId: 43,
      } || null,
    }));
    const variations = [
      {
        count: 1000,
        compensationOnly: false,
        productId: null,
        attributeValues: [...(customAttributes  || []), ...data.attrs.map((attr) => ({
          attributeId: attr.attributeId || null,
          value: attr.value,
          productId: null,
          valueTranslations: [
            { languageCode: "ru", text: attr.valueTranslations.ru || "" },
            { languageCode: "uz-Latn-UZ", text: attr.valueTranslations?.["uz-Latn-UZ"] || "" },
            { languageCode: "uz-Cyrl-UZ", text: attr.valueTranslations?.["uz-Cyrl-UZ"] || "" },
          ],
          variationId: null,
          attribute: null
        
        }))],
        prices: data?.prices.map((price) => ({
          currencyId: 1,
          value: price.value === "" ? 0 : price.value,
          variationId: null,
          type: price.type,
        })),
        files: uploadedFiles,
        saleType: calculateSaleType(),
      },
    ];
    const attributeId = data?.categoryId?.value;
    const datas = {
      name: names,
      description: descriptions,
      brandId: 1,
      categoryId: attributeId,
      organizationId: profile?.result?.organizationId,
      variations: variations,
    };

    try {
      await ProductsPost(datas);
      toggleTab(activeTab + 1, 100);
      client.invalidateQueries({ queryKey: ["moderations"] });
      navigate("/products-moderate");
    } catch (error) {
      alert(error?.data?.errors?.errorMessage);
    } finally {
      setProductLoading(false);
    }
  };

  const { fields } = useFieldArray({
    control,
    name: "attrs",
  });

  const addAttribute = () => {
    setAttribute([ 
    ...attribute,
      {
        attribute: {
          weight: 0,
          dataType: "Text",
          hasFilter: false,
          isValueTranslated: true,
          isAdditional: true,
          isRequired: true,
          id: null,
            isVisible: true,
            categoryId: 1,
        name: {
          ru: "",
          "uz-Latn-UZ": "",
          "uz-Cyrl-UZ": "",
        },
        description: {
          ru: "",
          "uz-Latn-UZ": "",
          "uz-Cyrl-UZ": "",
        },
      },
      valueTranslations: {
        ru: "",
        "uz-Latn-UZ": "",
        "uz-Cyrl-UZ": "",
      },
      attributeId:  null,
          value: "",
          productId: null,
          variationId: null
      }
    ]);
  };

  const handleDelete = (index) => {
    const updatedAttributes = [...attribute];
    updatedAttributes[index] = false; 
    updatedAttributes.splice(index, 1);
    setAttribute(updatedAttributes);
  };
  

  return (
    <div className="page-content">
      <Col className="d-flex justify-content-center">
        <Card className="w-75">
          <CardBody>
            <AddProductForm
              loading={loading}
              Submit={Submit}
              setValue={setValue}
              setShow={setShow}
              show={reset}
              attribute={() => setAttribute([])}
            />

            {loading ? (
              <div className="d-flex justify-content-center">
                <Spinner />
              </div>
            ) : (
              show && (
                <Form>
                  <div className="d-flex flex-column gap-3">
                    <div>
                      <Label style={{ fontSize: "15px" }}>{t("name_ru")}</Label>
                      <Input
                        control={control}
                        errors={errors}
                        name="name.ru"
                        inputProps={{
                          placeholder: t("Enter the title"),
                        }}
                      />
                      {errors.name && errors.name.ru && (
                        <div className="text-danger">
                          {t(errors.name.ru.message)}
                        </div>
                      )}
                    </div>

                    <div>
                      <Label style={{ fontSize: "15px" }}>
                        {t("name_cyrl")}
                      </Label>
                      <Input
                        control={control}
                        errors={errors}
                        name="name.uz-Cyrl-UZ"
                        inputProps={{
                          placeholder: t("Enter the title"),
                        }}
                      />

                      {errors.name && errors.name["uz-Cyrl-UZ"] && (
                        <div className="text-danger">
                          {t(errors.name["uz-Cyrl-UZ"].message)}
                        </div>
                      )}
                    </div>
                    <div>
                      <Label style={{ fontSize: "15px" }}>
                        {t("name_lat")}
                      </Label>
                      <Input
                        control={control}
                        errors={errors}
                        name="name.uz-Latn-UZ"
                        inputProps={{
                          placeholder: t("Enter the title"),
                        }}
                      />
                      {errors.name && errors.name["uz-Latn-UZ"] && (
                        <div className="text-danger">
                          {t(errors.name["uz-Latn-UZ"].message)}
                        </div>
                      )}
                    </div>

                    <div>
                      <Label style={{ fontSize: "15px" }}>
                        {t("description_ru")}
                      </Label>
                      <Textarea
                        control={control}
                        errors={errors}
                        name="description.ru"
                        inputProps={{
                          placeholder: t("Enter the title"),
                          type: "textarea",
                        }}
                      />
                      {errors.description && errors.description.ru && (
                        <div className="text-danger">
                          {t(errors.description.ru.message)}
                        </div>
                      )}
                    </div>

                    <div>
                      <Label style={{ fontSize: "15px" }}>
                        {t("description_cyrl")}
                      </Label>
                      <Textarea
                        control={control}
                        errors={errors}
                        name="description.uz-Cyrl-UZ"
                        inputProps={{
                          placeholder: t("Enter the title"),

                          // height: "300px"
                        }}
                      />
                      {errors.description &&
                        errors.description["uz-Cyrl-UZ"] && (
                          <div className="text-danger">
                            {t(errors.description["uz-Cyrl-UZ"].message)}
                          </div>
                        )}
                    </div>
                    <div>
                      <Label style={{ fontSize: "15px" }}>
                        {t("description_lat")}
                      </Label>
                      <Textarea
                        control={control}
                        errors={errors}
                        name="description.uz-Latn-UZ"
                        inputProps={{
                          placeholder: t("Enter the title"),
                          type: "textarea",
                        }}
                      />
                      {errors.description &&
                        errors.description["uz-Latn-UZ"] && (
                          <div className="text-danger">
                            {t(errors.description["uz-Latn-UZ"].message)}
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="d-flex gap-3 align-items-center mt-3">
                    <div className="w-100">
                      <Label>
                        {t("Price")}
                        <span className="text-danger">*</span>
                      </Label>
                      <Controller
                        control={control}
                        name="prices[0].value"
                        render={({ field }) => (
                          <ReactInput
                            {...field}
                            type="number"
                            onWheel={(e) => e.target.blur()}
                          />
                        )}
                      />
                      {errors?.prices?.[0]?.value && (
                        <div className="text-danger">
                          {t(errors?.prices[0]?.value?.message)}
                        </div>
                      )}
                    </div>
                    <div className="w-100">
                      <Label>
                        {t("Including VAT")}{" "}
                        <span className="text-danger">*</span>
                      </Label>
                      <Controller
                        control={control}
                        name="prices[1].value"
                        render={({ field }) => (
                          <ReactInput
                            {...field}
                            type="number"
                            onWheel={(e) => e.target.blur()}
                          />
                        )}
                      />
                      {errors?.prices?.[1]?.value && (
                        <div className="text-danger">
                          {t(errors?.prices[1]?.value?.message)}
                        </div>
                      )}
                    </div>
                    <div className="w-100">
                      <Label>
                        {t("MSRP")}
                        <span className="text-danger">*</span>
                      </Label>
                      <Controller
                        control={control}
                        name="prices[2].value"
                        render={({ field }) => (
                          <ReactInput
                            {...field}
                            type="number"
                            onWheel={(e) => e.target.blur()}
                          />
                        )}
                      />
                      {errors?.prices?.[2]?.value && (
                        <div className="text-danger">
                          {t(errors?.prices?.[2]?.value?.message)}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="mb-3 mt-2">
                    <Label htmlFor="sale-type">{t("Transaction type")}</Label>
                    <div className="d-flex flex-column">
                      <div className="form-check">
                        <ReactInput
                          type="checkbox"
                          id="order-available"
                          value={2}
                          onChange={() => handleSaleTypeChange(2)}
                          checked={watch("saleType").includes(2)}
                        />
                        <Label htmlFor="order-available">Компенсация</Label>
                      </div>

                      <div className="form-check">
                        <ReactInput
                          type="checkbox"
                          id="pre-order"
                          value={1}
                          onChange={() => handleSaleTypeChange(1)}
                          checked={watch("saleType").includes(1)}
                        />
                        <Label htmlFor="pre-order">Рассрочка</Label>
                      </div>

                      <div className="form-check">
                        <ReactInput
                          type="checkbox"
                          id="order-in-2-4-weeks"
                          value={4}
                          onChange={() => handleSaleTypeChange(4)}
                          checked={watch("saleType").includes(4)}
                        />
                        <Label htmlFor="order-in-2-4-weeks">Продажа</Label>
                      </div>
                      <div className="form-check">
                        <ReactInput
                          type="checkbox"
                          id="BMT"
                          value={8}
                          onChange={() => handleSaleTypeChange(8)}
                          checked={watch("saleType").includes(8)}
                        />
                        <Label htmlFor="BMT">ООН</Label>
                      </div>
                    </div>
                    {errors.saleType && (
                      <div className="text-danger">
                        {errors.saleType.message}
                      </div>
                    )}
                  </div>

                  <div className="d-flex gap-2 flex-wrap">
                    {fileInputs.map((input, index) => (
                      <div
                        key={index}
                        style={{
                          width: "200px",
                          position: "relative",
                        }}
                        draggable
                        onDragStart={() => handleDragStart(index)}
                        onDragOver={handleDragOver}
                        onDrop={() => handleDrop(index)}
                      >
                        <Dropzone
                          maxSize={maxFileSize}
                          disabled={filePreviews[index]}
                          onDropRejected={onDropRejected}
                          onDropAccepted={onDropAccepted}
                          onDrop={(acceptedFiles) =>
                            handleFileUpload(acceptedFiles)
                          }
                        >
                          {({
                            getRootProps,
                            getInputProps,
                            isDragActive,
                            isDragReject,
                          }) => (
                            <div
                              {...getRootProps({ className: "dropzone" })}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                             
                                border: "2px dashed #cccccc",
                                borderRadius: "5px",
                                cursor: !filePreviews[index]
                                  ? "pointer"
                                  : "move",
                                backgroundColor: isDragActive
                                  ? "#e6f7ff"
                                  : isDragReject
                                  ? "#ffcccc"
                                  : "white",
                              }}
                            >
                              {!filePreviews[index] && (
                                <div className="d-flex align-items-center justify-content-center">
                                  <i className="bx bx-image-add display-4"></i>
                                </div>
                              )}
                              {filePreviews[index] && (
                                <img
                                  src={filePreviews[index]}
                                  alt={`Preview ${index}`}
                                  style={{
                                    maxWidth: "100%",
                                    maxHeight: "180px",
                                    objectFit: "cover",
                                  }}
                                />
                              )}
                            </div>
                          )}
                        </Dropzone>
                        <Button
                          style={{ position: "absolute", top: 0, right: 0 }}
                          hidden={!filePreviews[index]}
                          onClick={() => handleDeleteImage(index, input - 1)}
                          color="danger"
                        >
                          {t("Delete")}
                        </Button>
                      </div>
                    ))}
                  </div>
                  <div className="d-flex flex-column gap-1">
                    {errors.files && (
                      <span className="text-danger">
                        {errors.files.message}
                      </span>
                    )}
                    <span
                      ref={errorMessageRef}
                      style={{ color: "red", marginTop: "10px" }}
                    ></span>
                  </div>

                  <div className="d-flex flex-column gap-3">
                    {fields.map(
                      (item, idx) =>
                        item.isVisible && (
                          <div key={idx}>
                            {item.filter ? (
                              item.filter.filterType === "Range" ||
                              item.filter.filterType === "Price" ? (
                                <div>
                                  <div className="d-flex flex-column">
                                    <Label style={{ fontWeight: 550 }}>
                                      {item.name}
                                    </Label>
                                    <span style={{ color: "gray" }}>
                                      {item?.filter?.values}
                                    </span>
                                  </div>
                                  <Controller
                                    control={control}
                                    name={`attrs[${idx}].value`}
                                    render={({ field }) => (
                                      <ReactInput
                                        {...field}
                                        type="number"
                                        onWheel={(e) => e.target.blur()}
                                      />
                                    )}
                                  />
                                  {errors.attrs &&
                                    errors.attrs[idx] &&
                                    errors.attrs[idx].value && (
                                      <span className="text-danger">
                                        {errors.attrs[idx].value.message}
                                      </span>
                                    )}
                                </div>
                              ) : item.filter.filterType === "SingleSelect" ? (
                                <div>
                                  <Label style={{ fontWeight: 550 }}>
                                    {item.name}
                                  </Label>
                                  {item.filter.values
                                    .split(",")
                                    .map((value, valueIdx) => (
                                      <div
                                        key={valueIdx}
                                        className="form-check"
                                      >
                                        <Controller
                                          control={control}
                                          name={`attrs[${idx}].value`}
                                          render={({ field }) => (
                                            <ReactInput
                                              {...field}
                                              value={value.trim()}
                                              type={"radio"}
                                              id={value}
                                            />
                                          )}
                                        />
                                        <Label
                                          htmlFor={value}
                                          className="form-check-label"
                                        >
                                          {value === "0"
                                            ? t("Yes")
                                            : value === "1"
                                            ? t("No")
                                            : value}
                                        </Label>
                                      </div>
                                    ))}
                                  {errors.attrs &&
                                    errors.attrs[idx] &&
                                    errors.attrs[idx].value && (
                                      <span className="text-danger">
                                        {errors.attrs[idx].value.message}
                                      </span>
                                    )}
                                </div>
                              ) : item.filter.filterType ===
                                "MultipleSelect" ? (
                                <div>
                                  <Label style={{ fontWeight: 550 }}>
                                    {item.name}
                                  </Label>
                                  {item.filter.values
                                    .split(",")
                                    .map((value, valueIdx) => (
                                      <div
                                        key={valueIdx}
                                        className="form-check"
                                      >
                                        <Controller
                                          control={control}
                                          name={`attrs[${idx}].value`}
                                          render={({ field }) => (
                                            <ReactInput
                                              {...field}
                                              value={value.trim()}
                                              type="checkbox"
                                              checked={field.value
                                                .split(",")
                                                .includes(value.trim())}
                                              onChange={(e) =>
                                                handleMultipleSelectChange(
                                                  field,
                                                  value,
                                                  e
                                                )
                                              }
                                              id={value.trim()}
                                            />
                                          )}
                                        />
                                        <Label
                                          htmlFor={value.trim()}
                                          className="form-check-label"
                                        >
                                          {value}
                                        </Label>
                                      </div>
                                    ))}
                                  {errors.attrs &&
                                    errors.attrs[idx] &&
                                    errors.attrs[idx].value && (
                                      <span className="text-danger">
                                        {errors.attrs[idx].value.message}
                                      </span>
                                    )}
                                </div>
                              ) : null
                            ) : !item.isValueTranslated ? (
                              <div>
                                <Label>{item.name}</Label>
                                <Controller
                                  control={control}
                                  name={`attrs[${idx}].value`}
                                  render={({ field }) => (
                                    <ReactInput
                                      type={
                                        item.dataType === "Float" ||
                                        item.dataType === "Integer" ||
                                        item.dataType === "Number"
                                          ? "number"
                                          : "text"
                                      }
                                      onWheel={(e) => e.target.blur()}
                                      {...field}
                                    />
                                  )}
                                />
                                {errors.attrs &&
                                  errors.attrs[idx] &&
                                  errors.attrs[idx].value && (
                                    <span className="text-danger">
                                      {errors.attrs[idx].value.message}
                                    </span>
                                  )}
                              </div>
                            ) : (
                              item.isValueTranslated && (
                                <div>
                                  <Label>{item.name}</Label>
                                  <div className="d-flex  gap-3">
                                    <div className="w-100">
                                      <InputLabel
                                        control={control}
                                        errors={errors}
                                        name={`attrs[${idx}].valueTranslations.ru`}
                                        label={t("ru")}
                                      />
                                      {errors.attrs &&
                                        errors.attrs[idx] &&
                                        errors.attrs[idx]
                                          ?.valueTranslations && (
                                          <span className="text-danger">
                                            {
                                              errors.attrs[idx]
                                                ?.valueTranslations?.ru?.message
                                            }
                                          </span>
                                        )}
                                    </div>

                                    <div className="w-100">
                                      <InputLabel
                                        control={control}
                                        errors={errors}
                                        name={`attrs[${idx}].valueTranslations.uz-Latn-UZ`}
                                        label={t("uz")}
                                      />
                                      {errors.attrs &&
                                        errors.attrs[idx] &&
                                        errors.attrs[idx]
                                          ?.valueTranslations && (
                                          <span className="text-danger">
                                            {
                                              errors.attrs[idx]
                                                ?.valueTranslations?.[
                                                "uz-Latn-UZ"
                                              ]?.message
                                            }
                                          </span>
                                        )}
                                    </div>
                                    <div className="w-100">
                                      <InputLabel
                                        control={control}
                                        errors={errors}
                                        name={`attrs[${idx}].valueTranslations.uz-Cyrl-UZ`}
                                        label={t("cryl")}
                                      />
                                      {errors.attrs &&
                                        errors.attrs[idx] &&
                                        errors.attrs[idx]
                                          ?.valueTranslations && (
                                          <span className="text-danger">
                                            {
                                              errors.attrs[idx]
                                                ?.valueTranslations?.[
                                                "uz-Cyrl-UZ"
                                              ]?.message
                                            }
                                          </span>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        )
                    )}
                    
                    {attribute.map((attr, index) => (
                     <div key={index}>
                   <div className="d-flex justify-content-end mb-3">
                    <Button type="button" onClick={() => handleDelete(index)} color="danger" >{t("Delete")}</Button>
                   </div>
                    <div className="d-flex  gap-3">
                     <div className="w-100">
                     
                             <InputLabel
                                      
                                       errors={errors}
                                       
                                       label={t("ru")}
                                       
                                       inputProps={{
                                         value: attr.attribute.name.ru,
                                         onChange: (e) => {
                                          const payload = [...attribute];
                                          payload[index].attribute.name.ru = e.target.value;
                                          setAttribute(payload)
                                        },
                                        placeholder: t("in russian")
                                        }
                                       }
                                     />
                     </div>

                      <div className="w-100">
                       
                              <InputLabel
                                       
                                       errors={errors}
                                       
                                       label={t("uz")}
                                       
                                      inputProps={{
                                        placeholder: t("in uzbek(lat)"),
                                       value: attr.attribute.name['uz-Latn-UZ'],
                                       onChange: (e) => {
                                        const payload = [...attribute];
                                        payload[index].attribute.name['uz-Latn-UZ'] = e.target.value;
                                        setAttribute(payload)
                                      }
                                      }}
                                     />
                      </div>

                    <div className="w-100">
                       
                            <InputLabel
                                     
                                       errors={errors}
                                       
                                       label={t("cryl")}
                                      inputProps={
                                      {
                                        placeholder: t("in uzbek(kyr)"),
                                       value: attr.attribute.name['uz-Cyrl-UZ'],
                                       onChange: (e) => {
                                        const payload = [...attribute];
                                        payload[index].attribute.name['uz-Cyrl-UZ'] = e.target.value;
                                        setAttribute(payload)
                                      }
                                      }
                                      }
                                     />
                           </div>
                           </div>
                         
 <div className="d-flex  gap-3">
                                   <div className="w-100">
                                     <InputLabel
                                      
                                       errors={errors}
                                       
                                       label={t("ru")}
                                       
                                       inputProps={{
                                        placeholder: "Значение",
                                         value: attr.valueTranslations.ru,
                                         onChange: (e) => {
                                          const payload = [...attribute];
                                          payload[index].valueTranslations.ru = e.target.value;
                                          setAttribute(payload)
                                        }}
                                       }
                                     />
                                    
                                   </div>

                                   <div className="w-100">
                                     <InputLabel
                                       
                                       errors={errors}
                                       
                                       label={t("uz")}
                                       
                                      inputProps={{
                                        placeholder: "Значение лат",
                                       value: attr.valueTranslations['uz-Latn-UZ'],
                                       onChange: (e) => {
                                        const payload = [...attribute];
                                        payload[index].valueTranslations['uz-Latn-UZ'] = e.target.value;
                                        setAttribute(payload)
                                      }
                                      }}
                                     />
                                    
                                   </div>
                                   <div className="w-100">
                                     <InputLabel
                                     
                                       errors={errors}
                                       
                                       label={t("cryl")}
                                      inputProps={
                                      {
                                        placeholder: "Значение кир",
                                       value: attr.valueTranslations['uz-Cyrl-UZ'],
                                       onChange: (e) => {
                                        const payload = [...attribute];
                                        payload[index].valueTranslations['uz-Cyrl-UZ'] = e.target.value;
                                        setAttribute(payload)
                                      }
                                      }
                                      }
                                     />
                                   
                                   </div>
                                 </div>
                     </div>
                     
                    ))}

                  </div>

                  <div className="d-flex align-items-center gap-2 justify-content-end mt-2">
                    <Button
                      onClick={addAttribute}
                      type="button"
                      className="d-flex align-items-center gap-2"
                    >
                      {t("add_attribute")}
                    </Button>
                    <Button
                      onClick={handleSubmit(ProductSubmit)}
                      className="d-flex align-items-center gap-2"
                    >
                      {productLoading ? (
                        <Spinner size="sm" className="me-2">
                          Loading...
                        </Spinner>
                      ) : null}
                      {t("Save")}
                      <i className="ri-check-line"></i>
                    </Button>
                  </div>
                </Form>
              )
            )}
          </CardBody>
        </Card>
      </Col>
    </div>
  );
};

export default AddProducts;
