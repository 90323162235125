import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useMemo, useState } from "react";
import { getReviewQuery } from "./../../../queries/index";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
} from "reactstrap";
import { ToastContainer } from "react-toastify";
import TableContainer from "../../../Components/Common/TableContainer";
import ReviewInformation from "./Components/review-information";
import classnames from "classnames";
import useHookForm from "../../../hooks/useHookForm";
import { useTranslation } from "react-i18next";
import Rating from "react-rating";
import * as moment from "moment";
import createSchema from "../../../helpers/createSchema";
const schema = createSchema({});
const values = {
  page: 1,
  size: 20,
  content: "",
  status: "",
};
const OrganizationReview = () => {
  const { t } = useTranslation();
  const tableKey = "organization-review";
  const [productId, setProductId] = useState(null);

  const [reviewData, setReviewData] = useState(null);
  const { watch, setValue } = useHookForm(values, schema);
  const { data, isFetched } = useQuery({
    ...getReviewQuery({ type: 1, ...watch() }),
  });
  

  useEffect(() => {
    const storedPageSize = JSON.parse(localStorage.getItem("pageSizes")) || {};
    const pageSize = storedPageSize[tableKey] || watch("size");
    setValue("size", pageSize);
  }, [setValue]);

  const handlePageSizeChange = (e) => {
    const newSize = parseInt(e.target.value, 10);
    setValue("size", newSize);
    setValue("page", 1);
    const storedPageSizes = JSON.parse(localStorage.getItem("pageSizes")) || {};
    storedPageSizes[tableKey] = newSize;
    localStorage.setItem("pageSizes", JSON.stringify(storedPageSizes));
  };

  const MoreButton = (id) => {
    const review = data?.result?.items?.find((item) => item.id === id);
    setReviewData(review);

    setProductId(id);
  };

  const columns = useMemo(() => [
    {
      accessor: "date",
      filterable: false,
      Cell: ({ row }) => <>{moment(row.original.date).format("DD.MM.YYYY")} </>,
    },

    {
      accessor: "rating",
      filterable: false,
      Cell: ({ row }) => (
        <>
          <Rating
            initialRating={row?.original?.rating}
            emptySymbol="mdi mdi-star-outline text-muted "
            fullSymbol="mdi mdi-star text-warning "
            className="fs-4"
            readonly
          />
        </>
      ),
    },
    {
      accessor: "for.name",
      filterable: false,
    },

    {
      accessor: "id",
      filterable: false,
      Cell: ({ row }) => {
        return (
          <Button color="link" onClick={() => MoreButton(row?.original?.id)}>
            <i className="ri-eye-line"></i>
          </Button>
        );
      },
    },
  ]);

  console.log(
    data?.result?.items?.find((item) => item.id === productId),
    "id"
  );
  return (
    <div className="page-content">
      <ToastContainer position="top-center" />
      <Container fluid>
        <Row>
          <Col lg={12}>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h2>{t("Products")}</h2>
            </div>
            <div className="d-flex justify-content-between align-items-start gap-3">
              <Card id="orderList" className="w-75">
                <CardBody className="pt-0">
                  <Nav
                    className={`nav-tabs nav-tabs-custom nav-success`}
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: watch("status") === "" },
                          "fw-semibold"
                        )}
                        onClick={() => {
                          setValue("status", "");
                          setReviewData(null);
                          setProductId(null);
                        }}
                        href="#"
                      >
                        {t("All")}
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: watch("status") === 1 },
                          "fw-semibold"
                        )}
                        onClick={() => {
                          setValue("status", 1);
                          setReviewData(null);
                          setProductId(null);
                        }}
                        href="#"
                      >
                        {t("Active")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: watch("status") === 2 },
                          "fw-semibold"
                        )}
                        onClick={() => {
                          setValue("status", 2);
                          setReviewData(null);
                          setProductId(null);
                        }}
                        href="#"
                      >
                        {t("Not active")}
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <div>
                    {isFetched ? (
                      <div>
                       

                        <TableContainer
                          pagination={{
                            currentPage: watch("page"),
                            totalPages: data?.pagination?.TotalPages,
                            onChange: (page) => setValue("page", page),
                          }}
                          columns={columns}
                          data={
                            data?.result?.items.filter(
                              (item) => item.status !== 0
                            ) || []
                          }
                          isGlobalFilter={false}
                          divClass="table-responsive table-card mb-1"
                          tableClass="align-middle table-nowrap"
                          theadClass="table-light text-muted d-none"
                        >
                          {data?.result?.items?.length === 0 && (
                            <div className="text-center ">{t("No data")}</div>
                          )}
                        </TableContainer>

                        <Input
                          type="select"
                          name="pageSize"
                          id="pageSize"
                          value={watch("size")}
                          onChange={handlePageSizeChange}
                          className="w-auto"
                        >
                          <option value={20}>{t("20")}</option>
                          <option value={50}>{t("50")}</option>
                          <option value={100}>{t("100")}</option>
                        </Input>
                      </div>
                    ) : (
                      <Spinner />
                    )}
                  </div>
                </CardBody>
              </Card>

              <div style={{position: "sticky",
          top: "85px",
          zIndex: 999 }}>
                <ReviewInformation
                  data={data?.result?.items?.find(
                    (item) => item.id === productId
                  )}
                  productId={productId}
                />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default OrganizationReview;
