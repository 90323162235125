import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { Navigate, Route, useLocation, useNavigate } from "react-router-dom";
import { getProfileQuery } from "../queries";
import { getOrganizations } from "../api";

const AuthProtected = (props) => {
  const token = localStorage.getItem("token");
  const location = useLocation();
  const { data: profile } = useQuery({
    ...getProfileQuery(),
  });
  const profileId = profile?.result?.organizationId;

  const { data } = useQuery({
    queryKey: ["organizations", profileId],
    queryFn: () => getOrganizations(profileId),
    enabled: !!profileId,
  });

  const navigate = useNavigate();
  useEffect(() => {
    if (!token) {
      navigate("/login");
      return;
    }
    if (
      data?.result?.state === 0 ||
      data?.result?.state === 2 ||
      data?.result?.state === 3 ||
      data?.result?.state === 4
    ) {
      if (
        location.pathname !== "/profile" &&
        location.pathname !== "/organization/edit"
      ) {
        navigate("/profile");
      }
    } else {
      if (location.pathname === "/organization/edit") {
        navigate("/organization/edit");
      }
      
    }
  }, [token, data, navigate, location.pathname]);
  return <>{props.children}</>;
};

const AccessRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <>
            <Component {...props} />
          </>
        );
      }}
    />
  );
};

export { AuthProtected, AccessRoute };
