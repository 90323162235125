import { useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ProductState, ProductsVisibility, getProductsId } from "../../../api";
import {
  Button,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import Cleave from "cleave.js/react";
import Dropzone from "react-dropzone";
import Select from "react-select";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import StateSelect from "../Components/StateSelect";
import Visibility from "../Components/Visibility";
import { InputLabel } from "../../../Components/atoms/inputLabel";

const ProductsSingle = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const clients = useQueryClient();
  const navigate = useNavigate();
  const { data, isLoading, error } = useQuery({
    queryKey: ["products-id", id],
    queryFn: () => getProductsId(id),
    throwOnError: (err) => {
      if (err.response && err.response.status === 400) {
        toast(err.response.data?.error?.errorMessage, {
          theme: "colored",
          type: "error",
        });
      } else {
        toast(err.data?.error?.errorMessage, {
          theme: "colored",
          type: "error",
        });
      }
    },
  });

  return (
    <div className="page-content">
      <ToastContainer position="top-center" />
      {isLoading ? (
        <Spinner />
      ) : (
        <div>
          <div className="d-flex align-items-start gap-3">
            <i
              className="ri-arrow-left-line fs-3 cursor-pointer"
              onClick={() => navigate(-1)}
            ></i>
            <h2>{t("Product information")}</h2>
          </div>
          <div className="d-flex gap-5 justify-content-between mb-3">
            <section className="w-50 d-flex flex-column gap-3">
              <StateSelect id={id} data={data} />
              <Label>{t("Visible")}</Label>

              <Visibility id={id} data={data} />
              <div>
                <Label className="fw-semibold">{t("Product category")}</Label>
                <Input
                  className="form-control border-dashed p-3"
                  value={data?.result?.category?.name}
                  readOnly
                />
              </div>
              <div className="d-flex flex-column gap-3">
                {data?.result?.names
                  ?.filter(
                    (item) =>
                      item.languageCode !== "en" &&
                      item.languageCode !== "uz-Cyrl-QQ"
                  )
                  .map((item) => (
                    <div key={item.id}>
                      <Label className="fw-semibold">
                        {item.languageCode === "ru"
                          ? t("Name Russian language")
                          : item.languageCode === "uz-Cyrl-UZ"
                          ? t("Name Uzbek language (Cyrill.)")
                          : item.languageCode === "uz-Latn-UZ"
                          ? t("Name Uzbek language (lat.)")
                          : item.languageCode === "en"
                          ? t("name_en")
                          : "test language"}
                      </Label>
                      <Input
                        className="form-control border-dashed p-3"
                        value={item.text}
                        readOnly
                      />
                    </div>
                  ))}
              </div>
              <div className="d-flex flex-column gap-3">
                {data?.result?.descriptions
                  ?.filter(
                    (item) =>
                      item.languageCode !== "en" &&
                      item.languageCode !== "uz-Cyrl-QQ"
                  )
                  .map((item) => (
                    <div key={item.id}>
                      <Label className="fw-semibold">
                        {item.languageCode === "ru"
                          ? t("Description Russian language")
                          : item.languageCode === "uz-Cyrl-UZ"
                          ? t("Description Uzbek language (Cyrill.)")
                          : item.languageCode === "uz-Latn-UZ"
                          ? t("Description Uzbek language (lat.)")
                          : item.languageCode === "en"
                          ? t("description_en")
                          : "test language"}
                      </Label>
                      <Input
                        className="form-control border-dashed p-3"
                        value={item.text}
                        readOnly
                      />
                    </div>
                  ))}
              </div>
              {data?.result?.variations?.length ? (
                <div>
                  <div>
                    <div className="d-flex flex-column gap-3">
                      {data?.result?.variations[0]?.prices?.map((item) => (
                        <div key={item.id}>
                          <Label className="fw-semibold">
                            {item.type === "Vat"
                              ? t("VAT amount")
                              : item.type === "Price"
                              ? t("Product price")
                              : item.type === "PayAmount"
                              ? t("Selling price")
                              : item.type === "Installment"
                              ? t("Installment price")
                              : item.type === "Compensation"
                              ? t("Compensation price")
                              : item.type === "Sale"
                              ? t("MSRP")
                              : null}
                          </Label>
                          <Cleave
                            placeholder="Enter numeral"
                            options={{
                              numeral: true,

                              numeralThousandsGroupStyle: "thousand",
                            }}
                            value={item.value}
                            className="form-control border-dashed p-3"
                            readOnly
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className=" w-100 gap-1 row p-3">
                    {data?.result?.variations[0]?.files?.map((item) => (
                      <Dropzone key={item.id} disabled={true}>
                        {({ getRootProps, getInputProps }) => (
                          <div
                            className="dropzone dz-clickable   col-md-6"
                            style={{ width: "280px" }}
                          >
                            <div
                              className="dz-message needsclick"
                              {...getRootProps()}
                            >
                              <img
                                style={{
                                  maxWidth: "300px",
                                  maxHeight: "250px",
                                  objectFit: "cover",
                                  borderRadius: "5px",
                                }}
                                src={item?.url}
                                alt={item?.id}
                              />
                            </div>
                          </div>
                        )}
                      </Dropzone>
                    ))}
                  </div>
                </div>
              ) : null}
            </section>
            {data?.result?.variations?.length ? (
              <section className="w-50 d-flex flex-column gap-3">
                {data?.result?.variations[0]?.attributeValues?.map((item) =>
                item.attribute.isVisible && (
                  !item.attribute.isValueTranslated ? (
                    <div key={item.id}>
                      <Label className="fw-semibold">
                        {" "}
                        {t(`${item?.attribute?.name}`)}
                      </Label>
                      <Input
                        className="form-control border-dashed p-3"
                        value={item.value}
                        readOnly
                      />
                    </div>
                  ) : (
                    item.attribute.isValueTranslated && (
                      <div>
                        <Label className="fw-semibold">
                          {" "}
                          {t(`${item?.attribute?.name}`)}
                        </Label>
                       
                          <div className="d-flex align-items-center gap-3">
                          {item?.valueTranslations
                            ?.filter(
                              (item) =>
                                item.languageCode !== "en" &&
                                item.languageCode !== "uz-Cyrl-QQ"
                            )
                            .map((item) => (
                              <div key={item.id} className="w-100">
                              
                                <InputLabel
                                  label={item.languageCode === "uz-Latn-UZ" ? t("uz") : item.languageCode === "uz-Cyrl-UZ" ? t("cryl") : t(item.languageCode)}
                                  inputProps={{
                                    className: "form-control border-dashed p-3 w-100",
                                    value: item.text,
                                    readOnly: true
                                  }}
                                />
                              </div>
                            ))}
                        </div>
                      </div>
                    )
                  )
                )
                )}
              </section>
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductsSingle;
