import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { Button, Form, Label, Spinner } from "reactstrap";
import Select from "react-select";
import { Input } from "../../../../Components/atoms/input";
import createSchema from "../../../../helpers/createSchema";
import useHookForm from "../../../../hooks/useHookForm";
import { t } from "i18next";
import { getCategoriesQuery } from "../../../../queries";
import { useQuery } from "@tanstack/react-query";
import { Textarea } from "../../../../Components/atoms/textarea";
const values = {
  name: {
    ru: "",
    "uz-Cyrl-UZ": "",

    "uz-Latn-UZ": "",
  },
  description: {
    ru: "",
    "uz-Cyrl-UZ": "",

    "uz-Latn-UZ": "",
  },
  categoryId: null,
  parentCategoryId: null,
  SubcategoryId: null,
 
};
const AddProductForm = ({ Submit, setValue, loading, setShow, show, attribute}) => {
  const schema = createSchema({
   
    
  }); //validatsiya
  const {
    control,
    watch,
    handleSubmit,
    reset,
    formState: { errors , isValid},
  } = useHookForm(values, schema);
  const [selectDisabled, setSelectDisabled] = useState(false);
  
  const onSubmit = handleSubmit((data) => {
    let categoriesId;

    if (childSubCategories.length > 0 && data.categoryId) {
      categoriesId = data.categoryId;
    } else if (childCategories.length > 0 && data.SubcategoryId) {
      categoriesId = data.SubcategoryId;
    } else {
      categoriesId = data.parentCategoryId;
    }
    setValue("categoryId", categoriesId);
    
    setSelectDisabled(true);
    Submit();
  });

  const { data: categories } = useQuery({
    ...getCategoriesQuery(),
  });

  const selectedCategoryId = watch("parentCategoryId");
  const selectedSubCategoryId = watch("SubcategoryId");
  const childCategories = selectedCategoryId
    ? categories?.item?.find(
        (category) => category.id === selectedCategoryId.value
      )?.childs || []
    : [];

  const childSubCategories = selectedSubCategoryId
    ? childCategories.find(
        (subCategory) => subCategory.id === selectedSubCategoryId.value
      )?.childs || []
    : [];
const disabledCategory = () => {
  setSelectDisabled(false)
  reset(values)
  setShow(false)
  show(false)
 attribute(false)
}



 useEffect(() => {
    if (show) {
      reset(values);
      
    }
  }, [show, reset]);



  return (
    <div>
      <Form className="d-flex flex-column gap-3 mt-2">
        <div>
          <Label style={{fontSize: "15px"}} >{t("product category")}</Label>
          <Controller
            name="parentCategoryId"
            
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                options={categories?.item?.map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
                isDisabled={selectDisabled}
              />
            )}
          />

        </div>
        {selectedCategoryId && childCategories.length > 0 && (
          <Controller
            name="SubcategoryId"
            
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                options={childCategories.map((childCategory) => ({
                  value: childCategory.id,
                  label: childCategory.name,
                }))}
                isDisabled={selectDisabled}
              />
            )}
          />
        )}

        {selectedSubCategoryId && childSubCategories.length > 0 && (
          <Controller
            name="categoryId"
            
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                options={childSubCategories.map((childCategory) => ({
                  value: childCategory.id,
                  label: childCategory.name,
                }))}
                isDisabled={selectDisabled}
              />
            )}
          />
        )}
       
      
 {selectDisabled ? (
          <div className="d-flex justify-content-end ">
            <Button disabled={loading} onClick={disabledCategory}>
              {t("edit")}
            </Button>
          </div>
        ) : (

        <div className="d-flex justify-content-end ">
          <Button onClick={handleSubmit(onSubmit)} disabled={ (childCategories.length > 0 && !watch("SubcategoryId")) ||
      (childSubCategories.length > 0 && !watch("categoryId")) ||
      !watch("parentCategoryId")}>
            {loading ? <Spinner size={"sm"} /> : t("Next")}
          </Button>
        </div>
        )}
      </Form>
    </div>
  );
};

export default AddProductForm;
