import React from "react";
import { useTranslation } from "react-i18next";

const Textfield = ({ name, source }) => {
  const { t } = useTranslation();
  return source ? (
    <div className="d-flex justify-content-between gap-5 align-items-center">
      <p style={{ ...css.text, opacity: 0.4 }}>{t(name)}</p>
      <p style={{ ...css.text, opacity: 0.8 }}>{source}</p>
    </div>
  ) : <>
   <p style={{ ...css.text, opacity: 0.4 }}>{t(name)}</p>
   </>;
};

export default Textfield;

const css = {
  text: {
    fontSize: "15px",
    fontWeight: 600,
  },
};
